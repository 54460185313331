import React from "react";
import { graphql } from "gatsby";
import { Grid } from "semantic-ui-react";
import Helmet from "react-helmet";

import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
  ContactForm,
} from "@fishrmn/fishrmn-components";

import emailCallback from "../utils/jobs-email.js";

import Layout from "../components/Layout";
import { getPageHeroImages } from "../utils/images";

export default class extends React.PureComponent {
  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage;
    const pageComponents = (pageData && pageData.components) || [];

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Volunteer</title>
          <meta name="description" content={pageData.description} />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"Volunteer"}
              tagline={""}
              images={getPageHeroImages(pageComponents)}
              showMap={false}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container rm-padding-bottom"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12}>
              <About
                content={
                  <div>
                    On-Farm Opportunities

                    Volunteer every Saturday from 10 AM to 1 PM

                    Please plan to arrive on time since orientation is an essential part of the
                    event.

                    Activities will be based upon the season and the needs of the farm.

                    Please bring a mask. Take care to maintain a 6-foot distance from others.
                    In cases where you may need to pass by within 6 feet, please wear your
                    mask. Our community’s safety is our top priority.
                  </div>
                }
                header={""}
                centerContent={true}
                headerAs={null}
                withContainer={true}
              />
            </Grid.Column>
          </Grid>

          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
          phone
          email
          address {
            street
            city
            state
            zip
          }
        }
      }
    }
    fishermanWebsitePage(title: { eq: "Volunteer" }) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
